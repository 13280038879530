import React, { Component } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  List,
  ListItem,
  ListItemText,
  Checkbox,
} from "@material-ui/core";
import { TabPanel, TabContext } from "@material-ui/lab";

export default class RentANodeDashboardCustomizationModal extends Component {
  constructor(props) {
    super(props);

    // initialize the state with the passed props
    this.state = {
      show: this.props.show,
      availableCards: this.props.availableCards || [],
      selectedCards: this.props.selectedCards || [],
    };

    // bind the handlers
    this.handleClose = this.handleClose.bind(this);
    this.handleCardToggle = this.handleCardToggle.bind(this);
    this.handleApplyChanges = this.handleApplyChanges.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedCards !== this.props.selectedCards) {
      this.setState({ selectedCards: this.props.selectedCards });
    }
    if (prevProps.show !== this.props.show) {
      this.setState({ show: this.props.show });
    }
  }

  // method to handle the modal closing
  handleClose() {
    this.props.handleClose();
  }

  handleCardToggle(cardName) {
    this.setState((prevState) => ({
      selectedCards: prevState.selectedCards.includes(cardName)
        ? prevState.selectedCards.filter((name) => name !== cardName)
        : [...prevState.selectedCards, cardName],
    }));
  }

  handleApplyChanges() {
    this.props.onApplyChanges(this.state.selectedCards);
    this.handleClose();
  }

  render() {
    const { availableCards, selectedCards } = this.state;

    return (
      <Dialog
        open={this.props.show}
        onClose={this.props.handleClose}
        fullWidth
        maxWidth="md"
      >
        <Box display="flex" justifyContent="center">
          <DialogTitle id="dialog-title">Customize the Rent-a-Node Dashboard</DialogTitle>
        </Box>
        <DialogContent>
          <List>
            {availableCards.map((card) => (
              <ListItem
                key={card}
                button
                onClick={() => this.handleCardToggle(card)}
              >
                <ListItemText primary={card} />
                {/* {selectedCards.includes(card) ? "Selected" : "Not Selected"} */}
                <Checkbox
                  checked={selectedCards.includes(card)}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <Box display="flex" justifyContent="center">
          <Box display="flex" flexDirection="column">
            <DialogActions style={{ flexDirection: "column", gap: "5px" }}>
              <Button
                onClick={this.handleApplyChanges}
                color="secondary"
                variant="contained"
                size="small"
              >
                Apply Changes
              </Button>
              <Button onClick={this.handleClose} color="primary" size="small">
                Cancel
              </Button>
            </DialogActions>
          </Box>
        </Box>
      </Dialog>
    );
  }
}